.full-width-height {
    width: 100%;
    height: 100%;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #000;
}

.selected-option-text {
    font-size: 12px;
    color: gray;
    margin-bottom: 5px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    background-color: #6eff94;
}

  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
    border-width: 1px 0 1px 1px;
    border: 3px solid #fff;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border: 3px solid #fff;
}

.custom-datatable .p-datatable-tbody tr:nth-child(odd) {
    background-color: #f8f8f8; /* Cor de fundo para as linhas ímpares */
  }
  
  .custom-datatable .p-datatable-tbody tr:nth-child(even) {
    background-color: #f0f0f0; /* Cor de fundo para as linhas pares */
  }

  .vertical-center {
    min-height: 100vh; /* Define a altura mínima para ocupar toda a altura da tela */
    display: flex;
    align-items: center;
  }

  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  /* Define o tamanho de 100% para as colunas */
  .col-3, .col-6 {
    height: 100%;
  }

.p-calendar .p-inputtext  {
  background-color: rgb(230, 230, 230);
}

.font-card-alarms {
	font-size: 18px;
	font-weight: 500;
}

.font-card-alarms-new-obs {
	font-size: 18px;
	font-weight: 500;
  min-width: max-content;
  margin-right: 14px;
}

.custom-switch__shape {
  height: 30px !important;
  width: 90px !important;
}
.custom-switch {
  padding-left: 0px !important;
}
.custom-switch__checkbox:checked + .custom-switch__shape:before {
  left: 60px !important;
}

/* .p-dialog .p-dialog-header {
  background-color: #8086c0;
  height: 60px;
} */

.button-edit-custom {
  text-align: -webkit-right;
}


/* Customize the file input button */
.custom-file-upload {
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
  background-color: #6eff94;
  color: #000;
  border-radius: 4px;
  border: none;
  transition: background-color 0.3s;
}

.custom-file-upload:hover {
  background-color: #6eff94;
}

/* Hide the default file input element */
input[type='file'] {
  display: none;
}

.p-datatable {
  font-size: 14px;
}

#modal {
  width: 70vw;
  .p-dialog-header {
      background-color: rgb(230, 230, 230) !important;
      height: 60px;
      padding: 2.2rem 1.5rem;
    }
}

#card-selected {
  margin-left: 30px;
  width: 30vw;
  .p-card-body {
      padding: 10px;
  }
}

.p-column-title {
  margin-right: 5px;
}

.p-dialog-header-icons {
  display: contents!important;
}

.p-card {
  box-shadow: none;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  /* margin-right: -40px;
  margin-bottom: 70px; */
  background-color: #fff;
  border: 2px #8086C0 solid;
}