/* custom-slider.css */

/* Mantém o estilo do Slider desabilitado igual ao habilitado */
.rc-slider-disabled {
    background-color: transparent; /* Remove o fundo cinza */
    opacity: 1; /* Mantém a opacidade total */
  }
  
  .rc-slider-disabled .rc-slider-track {
    background-color: #2db7f5; /* Mantém a cor da trilha */
  }
  
  .rc-slider-disabled .rc-slider-handle {
    border-color: #2db7f5; /* Mantém a cor da borda do handle */
    opacity: 1; /* Mantém a opacidade total */
  }
  
  .rc-slider-disabled .rc-slider-handle:active {
    box-shadow: 0 0 5px #2db7f5; /* Mantém o efeito de sombra ao clicar */
  }