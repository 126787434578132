.az-img-user {
    height: auto;
    text-align: center;
    font-size: x-large;
    color: black;
    cursor: pointer;
  }
  #dropdown-basic-button {
    background-color: #fff;
    color: #000;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
  }
  
  .custom-drop-item {
    width: 300px; /* Defina o valor de largura desejado aqui */
    white-space: nowrap; /* Impede que o texto seja quebrado em várias linhas */
    overflow: hidden; /* Esconde o texto que ultrapassa a largura do dropdown */
    text-overflow: ellipsis; 
  }
  
  .az-header-right {
    width: 0px;
  }
  
  #modal-email {
    .p-dialog-header {
        height: 80px;
      }
  }
  
  @media (max-width: 768px) {
    .img-logo {
      display: none;
    }
  }
  
  @media (max-width: 991px) {
    .img-logo {
      margin-left: 50px;
    }
  }
  
  @media (min-width: 993px) {
    .headerr-sizee {
      margin-right: auto;
    }
  }